import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userEmail:string;
  responseMSG:string; 
  isSuccess=false; 
  version = ''


  constructor(private userService: UserService, private dataService: DataService) {
  }

  ngOnInit() {
      this.version = this.dataService.getVersion()
  }
  onSubmit(): void {
      this.userService.forgotPassword(this.userEmail)
          .subscribe(
              data => this.handleResponse(JSON.stringify(data))
          );
  }

  textboxChanged() {
      this.responseMSG = null;
  }

  handleResponse(data: string) {
      let obj = JSON.parse(data);
      if (obj['status'] != 1) {
        this.isSuccess=false;  
          this.responseMSG = obj['message'];
      } else {    
        this.responseMSG = 'MSG.SENT_PASSWORD_RESET';
        this.isSuccess=true;    
        this.userEmail="";
      }
  }
}