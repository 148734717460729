import { Injectable } from '@angular/core';

import { DataService } from '../services/data.service';
import { Ticket } from 'src/app/models/ticket';
import { TicketType } from 'src/app/models/ticket-type';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {

    orderId:string;
    selectedTicketType:TicketType;
    startDate:string;
    numberTicket:number;
    tickets:Ticket[];
    canceledTickets:Ticket[];
    isFinished=false;

    constructor(private dataService: DataService) {

    }

    getTicketType() {
        let url = this.dataService.baseUrl + '/ticket_type?active=true';
        return this.dataService.getJSON(url,true).pipe(
          map(response => response.types)
        );
    }

    getGroupAge() {
        let url = this.dataService.baseUrl + '/group_age';
        return this.dataService.getJSON(url,true).pipe(
          map(response =>  response.groups)
        );
    }
    
    getNationalities() {
        let url = this.dataService.baseUrl + '/nationality';
        return this.dataService.getJSON(url,true).pipe(
          map(response =>  response.nationality)
        );
    }

    getExpiredDate(){
        var date = new Date(this.startDate);
        date.setUTCDate(date.getUTCDate()+this.selectedTicketType.num_expired_day-1);
        return date.toISOString();
    }

    postOrder() {
        var url = this.dataService.baseUrl + '/api/order';
        var date = this.getExpiredDate();
        return this.dataService.postJSON(url, {
            "token":this.dataService.getToken(),
            "status" : "unpaid",
            "ticket_type_id" : this.selectedTicketType._id,
            "start_date" : new Date(this.startDate),
            "expired_date" : date,
            "tickets" : this.tickets
        },true).pipe(
          map(response => response)
        );
    }

    postUpdateOrder() {
        var url = this.dataService.baseUrl + '/api/order/update';
        var date = new Date(this.startDate);
        date.setUTCDate(date.getUTCDate()+this.selectedTicketType.num_expired_day-1)
        return this.dataService.postJSON(url, {
            "token":this.dataService.getToken(),
            "status" : "unpaid",
            "order_id": this.orderId,
            "ticket_type_id" : this.selectedTicketType._id,
            "start_date" : this.startDate,
            "expired_date" : date.toISOString(),
            "tickets" : this.tickets.concat(this.canceledTickets)
        },true).pipe(
          map(response => response)
        );
    }

    postCancelOrder(id:string) {
        var url = this.dataService.baseUrl + '/api/order/cancel';
        return this.dataService.postJSON(url, {
            "token":this.dataService.getToken(),
            "id": id,
        },true).pipe(
          map(response => response)
        );
    }

    getOrderHistory(page: number) {
        let token = this.dataService.getToken();
        let requestUrl = this.dataService.baseUrl + "/api/order?token=" + token + "&page=" + page;
        return this.dataService.getJSON(requestUrl,true).pipe(
          map(response => response)
        );
    }

    getOrderHistoryDetail(id: string) {
        let token = this.dataService.getToken();
        let url = this.dataService.baseUrl + '/api/order/' + id + "?token=" + token;
        return this.dataService.getJSON(url,true).pipe(
          map(response => response)
        );
    }
    
    getReceipt(id: string) {
        let token = this.dataService.getToken();
        let url = this.dataService.baseUrl + '/api/receipt/' + id + "?token=" + token;
        return this.dataService.getJSON(url,true).pipe(
          map(response => response)
        );
    }
}
