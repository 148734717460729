import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private dataService: DataService, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.dataService.getToken()){
        return true;        
      }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    //this.router.navigateByUrl("/login");
    return false;
  }
}
