import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  editNameModel: ProfileName;
  changePWDModel: PWDChange;
  isEditName = false;
  isChangePWD = false;
  responseMSG = "";
  isSubmitted = false;
  isSuccess=false;

  constructor(private dataService: DataService, private userService: UserService) {
    this.editNameModel = new ProfileName();
  }

  ngOnInit() {

  }

  onEditName() {
    this.isSubmitted = false;
    this.isEditName = true;
    this.isChangePWD = false;
    this.editNameModel.first_name = this.dataService.getFirstName();
    this.editNameModel.last_name = this.dataService.getLastName();
    this.responseMSG="";
  }

  onSaveName() {
    this.userService.changeProfile(this.editNameModel)
      .subscribe(
        data => {
          if (data['status'] != 1) {
            this.responseMSG = data['message'];
          } else {
            this.isEditName = false;
            this.dataService.setName(this.editNameModel.first_name, this.editNameModel.last_name);
          }
        }
      );
  }

  onCancelEditName() {
    this.isEditName = false;
  }

  onChangePWD() {
    this.changePWDModel = new PWDChange();
    this.isEditName = false;
    this.isChangePWD = true;
    this.isSubmitted = false;
    this.isSuccess=false;
    this.responseMSG="";
  }

  onSavePWD() {
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%*]).{8,15}$/.test(this.changePWDModel.new_pwd)) {
      this.responseMSG = "MSG.PASSWORD_MUST_MATCH_PATTERN";
      return;
    } else if (this.changePWDModel.new_pwd != this.changePWDModel.confirm_new_pwd) {
      this.responseMSG = "MSG.PASSWORD_MISMATCH";
      return;
    }
    this.userService.changePWD(this.changePWDModel)
      .subscribe(
        data => {
          if (data['status'] != 1) {
            this.responseMSG = data['message'];
          } else {
            this.changePWDModel = new PWDChange();
            this.isSuccess=true;
            this.isSubmitted=false;
          }
        }
      );
  }

  onCancelChangePWD() {
    this.isChangePWD = false;
  }

  onInputChange(){
    this.isSubmitted=false;
    this.responseMSG="";
    this.isSuccess=false;
  }
}

export class ProfileName {
  first_name: string;
  last_name: string;
}

export class PWDChange {
  old_pwd: string;
  new_pwd: string;
  confirm_new_pwd: string;
}