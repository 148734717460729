import { Injectable, EventEmitter } from '@angular/core';
import { merge, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() {
  }
  getUTCDate(date: any) {
    if (date instanceof Date) {
      var d = date;
    } else {
      var d = new Date(date);
    }

    return this.formatStringLeadingZero("" + d.getUTCDate(), 2) + "-" + this.formatStringLeadingZero("" + (d.getUTCMonth() + 1), 2) + "-" + d.getUTCFullYear();
  }

  getDateTime(timestamp: number) {
    let d = new Date(timestamp);
    return this.formatStringLeadingZero("" + d.getDate(), 2) + "-" + this.formatStringLeadingZero("" + (d.getMonth() + 1), 2) + "-" + d.getFullYear() + " " + this.formatNumber2Digits(d.getHours()) + ":" + this.formatNumber2Digits(d.getMinutes());
  }

  formatNumber2Digits(num: number) {
    return ("0" + num).slice(-2)
  }

  formatStringLeadingZero(str: string, length: number) {
    var formattedString = ("000000000" + str).slice(-length);
    return formattedString;
  }

  formatNumber(str: string) {
    var num = parseInt(str);
    return parseFloat("" + Math.round(num * 100) / 100).toFixed(2);
  }

  getLang() {
    var lang = localStorage.getItem("lang");
    if (lang == "" || lang == undefined) {
      lang = "en";
    }
    return lang;
  }

  setLang(lang: string) {
    localStorage.setItem("lang", lang);
  }

  getAvailableLang() {
    return ['en', 'km'];
  }

  getNetworkChangeObservable(){
    return   merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true))
    )
  }
  public load(callback, ...urls) {
    let count = 0;
    let element;
    urls.forEach(url => {
      if (url.endsWith("js")) {
        element = document.createElement('script');
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
        document.getElementsByTagName('body')[0].appendChild(element);
      }
      if (url.endsWith("css")) {
        element = document.createElement('link');
        element.rel = "stylesheet";
        element.href = url;
        document.getElementsByTagName('head')[0].appendChild(element);
      }
      if (callback) {
        element.addEventListener("load", function () {
          count++;
          if (count == urls.length) {
            callback(urls);
          }
        });
      }
     
    })

  }
}
