import { Component,ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TicketService } from 'src/app/services/ticket.service';
import { TicketType } from 'src/app/models/ticket-type';
import { MatDialog } from '@angular/material';
import { MessageComponent } from 'src/app/components/message/message.component';
declare var $ :any;

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.css']
})

export class StepOneComponent implements OnInit {
  @ViewChild('datetimepicker') datetimepicker: ElementRef;
  ticketTypes: TicketType[];
  selectedTicket:TicketType;
  numberTicket=1;
  startDate:string;
  orderId:string;
  
  constructor(
    private ticketService: TicketService,
    private router: Router, 
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.orderId = params["id"]);
    if(this.orderId && (!this.ticketService.orderId || this.orderId != this.ticketService.orderId)){
      this.ticketService.getOrderHistoryDetail(this.orderId)
      .subscribe(data => {
        if(data.status==1){
          var o=data.order;
          if(o.status!='unpaid'){
            return this.router.navigateByUrl('/order');
          }
         
          this.ticketService.selectedTicketType=o.ticket_type;
          this.ticketService.startDate=o.start_date.toString();
          this.ticketService.numberTicket=o.tickets.length;
          this.ticketService.orderId=this.orderId;
          this.ticketService.tickets=o.tickets;
          this.ticketService.canceledTickets=[];

          var start_date=new Date(o.start_date);
          var defaultDate=new Date();
          defaultDate.setUTCHours(0,0,0,0)
          defaultDate.setDate(defaultDate.getDate()+1)
          if(start_date<defaultDate){
            this.ticketService.startDate=defaultDate.toISOString();
            this.dialog.open(MessageComponent, {
              data:{
                message:"MSG.ALERT_EXPIRED_START_DATE",
                isConfirm:false,
              },
              disableClose: false, role: 'dialog'
            });
          }
          
          this.ticketService.getTicketType()
            .subscribe(
              data => {
                this.ticketTypes = data;
                if (this.ticketTypes.length > 0)
                  if (this.ticketService.tickets) {
                    this.numberTicket = this.ticketService.tickets.length;
                    this.selectedTicket = this.ticketTypes.find(t => t._id == this.ticketService.selectedTicketType._id);
                    this.startDate = this.ticketService.startDate;
                    $(this.datetimepicker.nativeElement).data("DateTimePicker").date(this.startDate)
                  } else {
                    this.selectedTicket = this.ticketTypes[0];
                  }
              },
              error => console.log(error)
            );
        }
        
      });
    }else if(!this.orderId && this.ticketService.orderId){
      window.location.reload();
    }else{
      var start_date=new Date(this.ticketService.startDate);
          var defaultDate=new Date();
          defaultDate.setUTCHours(0,0,0,0)
          defaultDate.setDate(defaultDate.getDate()+1)
          if(start_date<defaultDate){
            this.ticketService.startDate=defaultDate.toISOString();
            this.dialog.open(MessageComponent, {
              data:{
                message:"MSG.ALERT_EXPIRED_START_DATE",
                isConfirm:false,
              },
              disableClose: false, role: 'dialog'
            });
          }
      this.ticketService.getTicketType()
      .subscribe(
        data => {
          this.ticketTypes = data;
          if (this.ticketTypes.length > 0)
            if (this.ticketService.tickets) {
              this.numberTicket = this.ticketService.tickets.length;
              this.selectedTicket = this.ticketTypes.find(t => t._id == this.ticketService.selectedTicketType._id);
              this.startDate = this.ticketService.startDate;              
              $(this.datetimepicker.nativeElement).data("DateTimePicker").date(this.startDate)
            } else {
              this.selectedTicket = this.ticketTypes[0];
            }
        },
        error => console.log(error)
      );
    }
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    var currentDate=new Date();
    currentDate.setDate(currentDate.getDate())
    currentDate.setHours(0,0,0,0)
    $(this.datetimepicker.nativeElement).datetimepicker({
      inline: true,
      format:'YYYY-MM-DD',
      minDate: currentDate.setDate(currentDate.getDate()+1)
    });
  }

  onClickTicket(ticket:TicketType){
    this.selectedTicket=ticket;
  }

  onPlusClick(){
    if(this.numberTicket<5)
      this.numberTicket++;
  }

  onMinusClick(){
    if(this.numberTicket>1)
      this.numberTicket--;
  }

  onAddToCart(){
    this.ticketService.startDate=(new Date($(this.datetimepicker.nativeElement).val())).toISOString();
    this.ticketService.selectedTicketType=this.selectedTicket;
    this.ticketService.numberTicket=this.numberTicket;
    //if has order id => user editing order
    if(this.orderId){
      this.router.navigateByUrl('/order/'+this.orderId+'/step/2');
    }else{
      this.router.navigateByUrl('/step/2');
    }
  }

  onCancel(route:string){
    let dialogRef = this.dialog.open(MessageComponent, {
      data:{
        message:"MSG.CONFIRM_CANCEL",
        isConfirm:true,
      },
      disableClose: true, role: 'dialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        window.location.href=route
      }
    });
    
  }
}
