import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/security/auth.guard';
import { LoginGuard } from 'src/app/security/login.guard';
import { RegisterComponent } from 'src/app/components/users/register/register.component';
import { LoginComponent } from 'src/app/components/users/login/login.component';
import { ForgotPasswordComponent } from 'src/app/components/users/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/components/users/reset-password/reset-password.component';
import { EmailVerificationComponent } from 'src/app/components/users/email-verification/email-verification.component';
import { StepOneComponent } from 'src/app/components/tickets/orders/step-one/step-one.component';
import { StepTwoComponent } from 'src/app/components/tickets/orders/step-two/step-two.component';
import { StepThreeComponent } from 'src/app/components/tickets/orders/step-three/step-three.component';
import { FinishComponent } from 'src/app/components/tickets/orders/finish/finish.component';
import { TicketComponent } from './components/tickets/ticket/ticket.component';
import { AppComponent } from './app.component';
import { MyOrderComponent } from './components/tickets/my-order/my-order.component';
import { MyOrderDetailComponent } from './components/tickets/my-order-detail/my-order-detail.component';
import { ReceiptComponent } from './components/tickets/receipt/receipt.component';
import { PromptCheckEmailComponent } from './components/users/prompt-check-email/prompt-check-email.component';
import { ProfileComponent } from './components/users/profile/profile.component';

const routes: Routes = [
  { path: 'logged',component:AppComponent, canActivate:[AuthGuard]},
  { path: 'login', component: LoginComponent, canActivate:[LoginGuard]},
  { path: 'register', component: RegisterComponent, canActivate:[LoginGuard]},
  { path: 'check-email', component: PromptCheckEmailComponent, canActivate:[LoginGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate:[LoginGuard] },
  { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate:[LoginGuard] },
  { path: 'verify/:token', component: EmailVerificationComponent, canActivate:[LoginGuard] },
  {path: '', redirectTo: 'step/1', pathMatch: 'full'}, 
  {
    path: '',component:TicketComponent, children: [
      { path: 'profile', component: ProfileComponent, canActivate:[AuthGuard] },
      { path: 'step/1',  component: StepOneComponent },
      { path: 'step/2',  component: StepTwoComponent },
      { path: 'step/3',  component: StepThreeComponent },
      { path: 'finish',  component: FinishComponent ,canActivate:[AuthGuard]},
      { path: 'order',  component: MyOrderComponent ,canActivate:[AuthGuard]},
      { path: 'order/:id', component: MyOrderDetailComponent ,canActivate:[AuthGuard]},
      { path: 'order/:id', canActivate:[AuthGuard],children:[
        { path: 'step/1',  component: StepOneComponent },
        { path: 'step/2',  component: StepTwoComponent },
        { path: 'step/3',  component: StepThreeComponent },
      ]},
      { path: 'receipt/:id',  component: ReceiptComponent ,canActivate:[AuthGuard] }
    ]
  },
  { path: '**', redirectTo: 'step/1' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),CommonModule],
  exports: [RouterModule],
  providers: [AuthGuard, LoginGuard]
})
export class AppRoutingModule { }
