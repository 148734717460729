import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxCaptchaModule} from 'ngx-captcha';
import {AppRoutingModule} from './/app-routing.module';

import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LanguageComponent} from './components/language/language.component';
import {UtilityService} from './services/utility.service';
import {UserService} from './services/user.service';
import {DataService} from './services/data.service';
import {HttpModule} from '@angular/http';
import {LoadingService} from './services/loading.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ResetPasswordComponent} from 'src/app/components/users/reset-password/reset-password.component';
import {LoginComponent} from 'src/app/components/users/login/login.component';
import {RegisterComponent} from 'src/app/components/users/register/register.component';
import {PromptCheckEmailComponent} from 'src/app/components/users/prompt-check-email/prompt-check-email.component';
import {EmailVerificationComponent} from 'src/app/components/users/email-verification/email-verification.component';
import {ForgotPasswordComponent} from 'src/app/components/users/forgot-password/forgot-password.component';
import {MatButtonModule, MatCardModule, MatDialogModule, MatIconModule, MatAutocompleteModule} from '@angular/material';
import {StepOneComponent} from './components/tickets/orders/step-one/step-one.component';
import {StepTwoComponent} from './components/tickets/orders/step-two/step-two.component';
import {StepThreeComponent} from './components/tickets/orders/step-three/step-three.component';
import {FinishComponent} from './components/tickets/orders/finish/finish.component';
import {TicketService} from './services/ticket.service';
import {TicketComponent} from './components/tickets/ticket/ticket.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImageCropperComponent} from 'src/app/components/image-cropper/image-cropper.component';
import {LoginPopupComponent} from './components/users/login-popup/login-popup.component';
import {ChatboxComponent} from './components/chatbox/chatbox.component';
import {MyOrderComponent} from './components/tickets/my-order/my-order.component';
import {MyOrderDetailComponent} from './components/tickets/my-order-detail/my-order-detail.component';
import {ConfirmationDialogComponent} from './components/chatbox/confirmation-dialog/confirmation-dialog.component';
import {MessageComponent} from './components/message/message.component';
import {ChatAuthInterceptor} from './interceptors/chat-auth.interceptor';
import { QRCodeModule } from 'angularx-qrcode';
import { ReceiptComponent } from './components/tickets/receipt/receipt.component';
import { ProfileComponent } from './components/users/profile/profile.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginPopupComponent,
    LanguageComponent,
    RegisterComponent,
    PromptCheckEmailComponent,
    EmailVerificationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ImageCropperComponent,
    TicketComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    FinishComponent,
    ChatboxComponent,
    MyOrderComponent,
    MyOrderDetailComponent,
    ConfirmationDialogComponent,
    MessageComponent,
    ReceiptComponent,
    ProfileComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatAutocompleteModule,
    NgxCaptchaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    HttpModule,
    QRCodeModule
  ],
  providers: [UtilityService, UserService, DataService, LoadingService, TicketService, {
    useClass: ChatAuthInterceptor,
    provide: HTTP_INTERCEPTORS,
    multi: true,
  }],
  entryComponents: [ImageCropperComponent, LoginPopupComponent, ConfirmationDialogComponent, MessageComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
