import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    userNewPWD: string;
    userConfirmNewPWD: string;
    token: string;
    responseMSG="";
    version="";

    constructor(private dataService:DataService,private userService: UserService, private router: Router, private route: ActivatedRoute) {
        this.version=this.dataService.getVersion();
        this.route.params.subscribe(params => this.token = params["token"]);
        this.userService.requestResetPassword(this.token)
            .subscribe(
                data => {
                    if (data['status'] != 1) {
                        this.router.navigateByUrl("/home");                        
                    } 
                }
            );
    }
    ngOnInit() {
    }

    validateToken() {

    }

    onSubmit(): void {      
        if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%*]).{8,15}$/.test(this.userNewPWD)){
            this.responseMSG="MSG.PASSWORD_MUST_MATCH_PATTERN";
            return;
        }else if(this.userNewPWD!=this.userConfirmNewPWD){
            this.responseMSG="MSG.PASSWORD_MISMATCH";
            return;
        }  
        this.userService.resetPassword(this.userNewPWD, this.token)
            .subscribe(
                data => {
                    if (data['status'] != 1) {
                        this.responseMSG = data['message'];          
                    }else {
                        this.router.navigateByUrl("/login");
                    }
                }
            );
    }
}