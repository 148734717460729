import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import * as io from 'socket.io-client';
import {Observer} from 'rxjs/internal/types';
import {share} from 'rxjs/operators';


@Injectable()
export class ChatService {
  public static readonly Host = environment.API_CHAT_URL;
  public static readonly TokenKey = 'APP_CHAT_TOKEN';
  public readonly socket = io(ChatService.Host);

  private getUrl(...endpoints: string[]): string {
    return ChatService.Host.concat(...endpoints);
  }

  constructor(private http: HttpClient) {

  }

  public joinRoom(roomId: string) {
    this.socket.emit('join-room', roomId);
  }

  public fromEvent<T = any>(event: string): Observable<T> {
    return Observable.create((observer: Observer<T>) => {
      this.socket.on(event, (data: T) => {
        observer.next(data);
      });
    }).pipe(share());
  }


  authenticateSocket(token: string) {
    this.socket.emit('authenticate', {token});
    console.log('Authenticating ');
  }


  public closeRoom(roomId: string): Observable<Room> {
    return this.http.post<Room>(this.getUrl('/rooms/', roomId, '/close-for-guest'), {});
  }

  public getGlobalPresence(): Observable<DataPresence> {
    return this.http.get<DataPresence>(this.getUrl('/presence'));
  }

  public postCreateGuest({name, email}: { name: string, email: string }): Observable<UserResponse> {
    return this.http.post<UserResponse>(this.getUrl('/guests'), {name, email});
  }

  public getMessages(roomId: string): Observable<DataMessage[]> {
    return this.http.get<DataMessage[]>(this.getUrl('/messages/', roomId));
  }

  public createTextMessage({body, roomId}: { body: string, roomId: string }): Observable<DataMessage> {
    return this.http.post<DataMessage>(this.getUrl('/messages'), {type: 1, body, roomId});
  }

  public getReadyRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(this.getUrl('/rooms/ready'));
  }

  public createRoom({name, maxLength}: { name: string, maxLength: number }): Observable<Room> {
    return this.http.post<Room>(this.getUrl('/rooms'), {name, maxLength});
  }

  public authByToken(token: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(this.getUrl('/guests/me'));
  }

  public hasStoredToken(): boolean {
    return !!this.getStoredToken();
  }

  public getStoredToken(): string {
    return localStorage.getItem(ChatService.TokenKey);
  }

  public saveToken(token: string) {

    localStorage.setItem(ChatService.TokenKey, token);
  }

  public clearToken() {
    localStorage.removeItem(ChatService.TokenKey);
  }

  public emitTypingToRoom(roomId: string) {
    this.socket.emit('typing', roomId);
  }
}

export interface DataMessage extends BaseModel {
  sender: string & Account;
  room: string & Room;
  type: number;
  body: string;
  status: number;
}

export interface Room extends BaseModel {
  name: string;
  members: string[] & Account[];
  creator: string & Account;
  maxLength: number;
  status: number;
}

export interface UserResponse {
  account: Account;
  expiresIn: number;
  token: string;
}

export interface User extends BaseModel {
  email: string;
  password: string;
  status: number;
  enabled: boolean;
  role: number;
}

export interface Account extends BaseModel {
  meta: Meta;
  type: 'guest' | 'user';
  name: string;
  user: User & string;
}

export interface Meta {
  email: string;
}

export interface BaseModel {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface DataPresence extends BaseModel {
  isOnline: boolean;
}

