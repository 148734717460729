import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  isLoading: boolean;
  isSuccess: boolean;
  token: string;

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.verifyToken();
  }

  verifyToken(): void {
    this.route.params.subscribe(params => this.token = params["token"]);

    this.userService.verifyEmail(this.token)
    .subscribe(data =>  {
      if(data.status == 1) {
        this.isSuccess = true;        
      }

      this.isLoading = false;
    });
  }

}
