import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {
    user: User;
    
    isWrong = false;
    version="";

    ngOnInit() {
        this.user=new User;
    }

    onSubmit(): void {
        this.userService.login(this.user.email, this.user.password)
            .subscribe(
                data => this.handleResponse(JSON.stringify(data))
            );
    }

    textboxChanged() {
        this.isWrong = false;
    }

    handleResponse(data: string) {
        let obj = JSON.parse(data);
        if (obj['token'] == undefined) {
            this.isWrong = true;
        } else {
            this.dataService.saveToken(obj['token']);
            this.dataService.setName(obj.user.first_name ,obj.user.last_name);
            this.dialogRef.close(true);
        }
    }

    constructor(
        private userService: UserService,
        private dataService: DataService,
        protected utilityService: UtilityService,
        private dialogRef:MatDialogRef<LoginPopupComponent>) {
            this.version=dataService.getVersion();
    }
}