import { TicketType } from './ticket-type';
import { GroupAge } from '../models/group-age';

export class Ticket {
    _id: string;
    photo : string;
    dob: string;
    nationality : string = "";
    sex : string;
    status: string="active";
    constructor() {
       
    }

}