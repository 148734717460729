import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from 'node_modules/@angular/router';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  id:string;
  order: any;
  constructor(private ticketService: TicketService,private activatedRoute: ActivatedRoute,private router:Router) {
    this.activatedRoute.params.subscribe(params => this.id = params["id"]);
    this.ticketService.getReceipt(this.id)
    .subscribe(data => this.responseDetail(data));
   }

  ngOnInit() {
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          @media print {
            .no-print{        
              display: none;
            }
          }
          *{
            -webkit-print-color-adjust:exact;
            color: #203e2e;
          }
          .content {
            font-family: fontSemibold, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, fontKhmer, "Khmer OS", "Khmer OS System";
            margin: 1px auto;
            margin-top: 1px;
            margin-bottom: 1px;
            display: block;
            width: 860px;
            padding: 30px;
            /*box-shadow: 0px 1px 4px #BABABA;*/
            margin-top: 100px;
            margin-bottom: 30px;
        }
        h3{
          font-size: 24px;
        }
        h4{
          ont-size: 18px;
        }
        .summary th{
            text-align: center;
        }
        
        th,td{
            padding: 7px;
        }
        .bg-light-green {
            background-color: #e2faed;
        }
        
        .tb-footer {
            height: 20px;
        }
        ul {
            padding-left: 15px;
        }
        .col-info-icon {
            padding: 7px 7px 0px 5px;
        }
        .btn-style {
            color: #ffffff;
            text-decoration: none;
            background-color: #da9031;
            padding: 12px 50px;
            border: 1px;
            border-radius: 5px;
            margin: 15px 0;
        }
        a {
            color: #e1a357;
        }
        .green-info {
            font-size: 25px;
            color: #203e2e;
            margin-top: 7px;
        }
        .tbl-ticket td{
            padding: 3px;
        }
        .tbl-ticket {
            margin-right: 15px;
        }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}

  responseDetail(response) {
    console.log(response)
    if (response['status'] == 1) {
      if(response.order.status!='paid'&&response.order.status!='completed'){
        this.router.navigateByUrl("order/"+this.id);
        return;
      }
      this.order = response['order'];
      console.log(this.order)
    }    
  }

}
