import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading=false;
  loadingEventEmitter :EventEmitter<boolean>=new EventEmitter();
  
  constructor() { }

  setLoading(isLoading:boolean) {
    this.isLoading = isLoading;
    this.loadingEventEmitter.emit(this.isLoading);
  } 

}
