import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  currentLang:string;
  changeLangLabel = "ភាសាខ្មែរ ";


  constructor(protected translate: TranslateService, protected utilityService: UtilityService) {
    this.translate.addLangs(this.utilityService.getAvailableLang());
    this.translate.setDefaultLang(this.utilityService.getLang());
  }

  ngOnInit() {
    this.currentLang = this.utilityService.getLang();
    if (this.currentLang == 'en') {
      this.changeLangLabel = "ភាសាខ្មែរ";
    } else {
      this.changeLangLabel = "English";
    }
  }

  changeLang() {
    if (this.currentLang == 'en') {
      this.changeLangLabel = "English";
      this.utilityService.setLang('km');
      this.currentLang = 'km';
      this.translate.setDefaultLang(this.utilityService.getLang());
    } else {
      this.changeLangLabel = "ភាសាខ្មែរ";
      this.utilityService.setLang('en');
      this.currentLang = 'en';
      this.translate.setDefaultLang(this.utilityService.getLang());
    }
  }
}
