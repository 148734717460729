import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TicketService } from 'src/app/services/ticket.service';
import { Ticket } from 'src/app/models/ticket';
import { GroupAge } from 'src/app/models/group-age';
import { ImageCropperComponent } from 'src/app/components/image-cropper/image-cropper.component';
import { MatDialog } from '@angular/material';
import { TicketType } from 'src/app/models/ticket-type';
import { MessageComponent } from 'src/app/components/message/message.component';
import { UtilityService } from 'src/app/services/utility.service';
import { LoadingService } from '../../../../services/loading.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
declare var $ :any;

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.css']
})
export class StepTwoComponent implements OnInit {
  private personalinfo: ElementRef;

  //use data("inited") to prevent db.change run when init data to datetimepicker
  @ViewChild('personalinfo') set content(content: ElementRef) {
    var that =this;
    this.personalinfo = content;
    if(this.personalinfo){
      var expired_date=new Date(this.ticketService.startDate)
      expired_date.setFullYear(expired_date.getFullYear()-12)
      expired_date.setDate(expired_date.getDate()+this.selectedTicketType.num_expired_day-1)
      $(this.personalinfo.nativeElement).find('.date').datetimepicker({
        format:'DD-MM-YYYY',
        maxDate: expired_date,
        minDate: new Date((expired_date.getFullYear()-200)+'-01-01'),
      }).on('dp.change', function(e) {
        var index = $(that.personalinfo.nativeElement).find('.date').index(this)    
        if(e.date && ($(this).data("inited") || that.tickets[index].dob!=null)){
          that.tickets[index].dob=(new Date(e.date.format('YYYY-MM-DD'))).toISOString();
        }else{
          if(!e.date){
            that.tickets[index].dob=null; 
          }
          that.clearDateTimePicker($(this));
        }
      });
    }
  }
  tickets: Ticket[] = [];
  groupAges: GroupAge[];
  nationalities:any;
  isClickedSubmit = false;
  selectedTicketType: TicketType;
  orderId:string;
  autocompleteControl = new FormControl();
  filterNationalities: Observable<any[]>;
  
  constructor(private ticketService: TicketService,
    private router: Router, 
    private dialog: MatDialog, 
    private route: ActivatedRoute, 
    private utilityService: UtilityService,
    private loadingService:LoadingService) {
    this.route.params.subscribe(params => this.orderId = params["id"]);
    if(this.orderId && (!this.ticketService.orderId || this.orderId != this.ticketService.orderId)){
      this.ticketService.getOrderHistoryDetail(this.orderId)
      .subscribe(data => {
        if(data.status==1){
          var o=data.order;
          if(o.status!='unpaid'){
            return this.router.navigateByUrl('/order');
          }
          this.ticketService.selectedTicketType=o.ticket_type;
          this.ticketService.startDate=o.start_date.toString();
          this.ticketService.numberTicket=o.tickets.length;
          this.ticketService.orderId=this.orderId;
          this.ticketService.tickets=o.tickets;
          this.ticketService.canceledTickets=[];

          var start_date=new Date(o.start_date);
          var defaultDate=new Date();
          defaultDate.setUTCHours(0,0,0,0)
          defaultDate.setDate(defaultDate.getDate()+1)
          if(start_date<defaultDate){
            this.router.navigateByUrl('/order/'+this.orderId+'/step/1');
          }

          this.selectedTicketType = this.ticketService.selectedTicketType;
          this.ticketService.getGroupAge()
            .subscribe(
              groupAges => {
                this.groupAges = groupAges;
                var number = this.ticketService.numberTicket;
                var length=0;
                if (this.ticketService.tickets) {
                  var length = this.ticketService.tickets.length;
                  if (number >= length) {
                    this.tickets = this.ticketService.tickets;
                    for (var i = 0; i < (number - length); i++) {
                      this.onAddTicket();
                    }
                  } else {
                    this.tickets = this.ticketService.tickets.slice(0, number);
                    this.ticketService.tickets=this.tickets;
                    this.ticketService.numberTicket = this.tickets.length;
                  }

                  this.initDateTimePicker(length);
                 
                } else {
                  for (var i = 0; i < number; i++) {
                    this.onAddTicket();
                  }
                }
                
                this.clearDateTimePickerLast(number-length);
              }
            );
          this.ticketService.getNationalities().subscribe(
            nationalities=>this.nationalities=nationalities
          );
        }
        
      });
    }else if(!this.orderId && this.ticketService.orderId){
      window.location.reload();
    }else{
      if (!this.ticketService.numberTicket) {
        this.router.navigateByUrl('/step/1');
      }
      this.selectedTicketType = this.ticketService.selectedTicketType;
      this.ticketService.getGroupAge()
        .subscribe(
          groupAges => {
            this.groupAges = groupAges;
            var number = this.ticketService.numberTicket;
            var length=0;
            if (this.ticketService.tickets) {
              length = this.ticketService.tickets.length;
              if (number >= length) {
                this.tickets = this.ticketService.tickets;
                for (var i = 0; i < (number - length); i++) {
                  this.onAddTicket();
                }
              } else {
                this.ticketService.tickets.forEach(ticket=>{
                  if(this.tickets.length<number){
                    this.tickets.push(ticket);
                  }else{
                    if(this.orderId){
                      if(ticket._id){
                        ticket.status="canceled";
                        this.ticketService.canceledTickets.push(ticket)
                      }
                    }
                  }
                });
               
                this.ticketService.tickets=this.tickets;
                this.ticketService.numberTicket = this.tickets.length;
              }

              this.initDateTimePicker(length);
  
            } else {
              for (var i = 0; i < number; i++) {
                this.onAddTicket();
              }
            }
            this.clearDateTimePickerLast(number-length);
          }
        );
      this.ticketService.getNationalities().subscribe(
        nationalities=>this.nationalities=nationalities
      );
    } 
  }

  ngOnInit() {
    
  }


  nationalityChange(newValue){
    var list =this.nationalities.filter(nationality=>nationality.nationality.toLowerCase().startsWith(newValue.toLowerCase()))    
    this.filterNationalities= list.length==0?this.nationalities:list;
   
  }

  nationalityfocus(ticket:Ticket){
    this.filterNationalities=this.nationalities.filter(nationality=>nationality.nationality.toLowerCase().startsWith(ticket.nationality.toLowerCase()))
  }

  close(ticket:Ticket){
    ticket.nationality=this.nationalities.find(x =>x.nationality==ticket.nationality)?ticket.nationality.toUpperCase():"";   
  }
  /**set dob to datetimepicker */
  private initDateTimePicker(length:number){
    
    // set time out 0 to make this process wait until render view complete
    setTimeout(() => {
      if(!this.personalinfo)
        return;
      var eleList=$(this.personalinfo.nativeElement).find('.date').toArray();
      var isLessThan12=false;
      for(var i = 0; i<length; i++){
        var dob = new Date(this.tickets[i].dob)
        var expired_date=new Date(this.ticketService.startDate)
        expired_date.setFullYear(expired_date.getFullYear()-12)
        expired_date.setDate(expired_date.getDate()+this.selectedTicketType.num_expired_day-1)
        if(dob>expired_date){
          isLessThan12=true;
          this.tickets[i].dob=null;
          this.clearDateTimePicker($(eleList[i]))
        }else{
          $(eleList[i]).data("DateTimePicker").date(this.utilityService.getUTCDate(dob.getTime()));
        }
      }
      if(isLessThan12){
        this.dialog.open(MessageComponent, {
          data:{
            message:"MSG.VISITOR_MUST_MORE_THAN_12",
            isConfirm:false,
          },
          disableClose: false, role: 'dialog'
        });
      }
    }, 0);
  }
  
  /**Use to clear date time picker value when init many elements because onAddTicket can clear only last element.*/
  private clearDateTimePickerLast(number:number){
    // set time out 0 to make this process wait until render view complete
    setTimeout(() => {
      if(!this.personalinfo)
        return;
      var eleList=$(this.personalinfo.nativeElement).find('.date').toArray();
      for(var i = 0; i<number; i++){
        this.clearDateTimePicker($(eleList[eleList.length-1-i]));
      }
     
    }, 0);
  }

  private clearDateTimePicker(ele:any){
    ele.data("DateTimePicker").clear();
    ele.data("inited",true)
  }

  fileChange(input: HTMLInputElement, ticket: Ticket) {
    if (input.files.length > 0) {
      const reader = new FileReader();
      reader.onload = e => {
        const ref = this.dialog.open(ImageCropperComponent, {
          data: reader.result,
          disableClose: true, 
          role: 'dialog'
        });
        ref.afterClosed().subscribe((canvas: HTMLCanvasElement) => {
          if (canvas) { 
            this.validatePhoto(canvas.toDataURL(),(src)=>{
              this.tickets[this.tickets.indexOf(ticket)].photo = src;
            })
          }
        });
      };
      reader.readAsDataURL(input.files[0]);
      input.value="";
    }
  }

  onTicketPhotoLoad(event:any,imgSrc:string){
    if(imgSrc.startsWith("data")){
      $(event.target).faceDetection({
        complete: function (faces) {
        }
      });
    }
    
  }

  onDelete(ticket:Ticket){
    let dialogRef = this.dialog.open(MessageComponent, {
      data:{
        message:"MSG.CONFIRM_DELETE_TICKET",
        isConfirm:true,
      },
      disableClose: true, role: 'dialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(ticket._id){
          ticket.status="canceled";
          this.ticketService.canceledTickets = this.ticketService.canceledTickets.concat(this.tickets.splice(this.tickets.indexOf(ticket),1)); 
        }else{
          this.tickets.splice(this.tickets.indexOf(ticket),1)      
        }
        this.ticketService.tickets = this.tickets;
        this.ticketService.numberTicket = this.tickets.length;
      }
    });
    
  }

  onAddTicket() {
    this.isClickedSubmit = false;
    var ticket = new Ticket()
    ticket.sex  = ticket.nationality = "";
    ticket.dob=null;
    this.tickets.push(ticket)
    this.ticketService.tickets = this.tickets;
    this.ticketService.numberTicket = this.tickets.length;
    // clear date for first init
    // set time out 0 to make this process wait until render view complete
    setTimeout(() => {
      this.clearDateTimePicker($(this.personalinfo.nativeElement).find('.date').last());
    }, 0);
  }

  onCountinue() {
    this.isClickedSubmit = true;
    if (!this.tickets.find(t => t.photo==undefined || !t.dob || t.nationality === "" || t.sex === "")) {
      if(this.orderId){
        this.router.navigateByUrl('/order/'+this.orderId+'/step/3');
      }else{
        this.router.navigateByUrl('/step/3');
      }
    }else{
      setTimeout(() => {
        if ($('.has-error').length > 0) {
          $('html, body').animate({
            scrollTop: $('.has-error').offset().top - 80
          }, 100);
        }
      }, (20));
    }
  }

  onCancel(route:string){
    let dialogRef = this.dialog.open(MessageComponent, {
      data:{
        message:"MSG.CONFIRM_CANCEL",
        isConfirm:true,
      },
      disableClose: true, role: 'dialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        window.location.href=route
      }
    });
    
  }

  validatePhoto(src:string,succeedCallBack){
    var that =this;           
    var img= new Image();
    this.loadingService.setLoading(true);
    img.onload=function(){
      $(img).faceDetection({
        complete: function (faces) {
          that.loadingService.setLoading(false);
          if(faces.length<1){
            that.dialog.open(MessageComponent, {
              data:{
                message:"MSG.NO_FACE_DETECT",
                isConfirm:false,
              },
              disableClose: false, role: 'dialog'
            });
          }else if(faces.length==1){
            var face=faces[0];
            if((face.width/img.width)>0.6){ //if too close
              that.dialog.open(MessageComponent, {
                data:{
                  message:"MSG.FACE_TOO_CLOSE",
                  isConfirm:false,
                },
                disableClose: false, role: 'dialog'
              });
            }else if((face.width/img.width)<0.25){ //if too far
              that.dialog.open(MessageComponent, {
                data:{
                  message:"MSG.FACE_TOO_FAR",
                  isConfirm:false,
                },
                disableClose: false, role: 'dialog'
              });
            }else if(Math.abs(img.width-face.width-face.x-face.x)/img.width>0.15){ //if not center
              that.dialog.open(MessageComponent, {
                data:{
                  message:"MSG.FACE_NOT_CENTER",
                  isConfirm:false,
                },
                disableClose: false, role: 'dialog'
              });
            }else{
              succeedCallBack(src);
            }
          }else if(faces.length>1){
            that.dialog.open(MessageComponent, {
              data:{
                message:"MSG.MANY_FACES_DETECT",
                isConfirm:false,
              },
              disableClose: false, role: 'dialog'
            });
          }
        },
        error: function (code, message) {         
         that.loadingService.setLoading(false);
        }
      });
    }
    img.src=src;
  }
}
