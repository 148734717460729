import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { DataService } from '../services/data.service';
import { map } from 'rxjs/operators'
import { Router } from '@angular/router';

@Injectable()
export class UserService {

    constructor(private dataService: DataService,private router:Router){
        this.baseUrl = dataService.baseUrl;
    }

    user: User;
    response: JSON;
    baseUrl: string;

    login(email: string, password: string) {
        let url = this.baseUrl + '/login_online';
        let data = {
            email: email,
            pwd: password
        };
        return this.dataService.postJSON(url, data).pipe(
          map(response => response)
        );
    }

    isLoggedIn(){
        return this.dataService.getToken()?true:false;
    }

    logout() {
        this.dataService.logout()
        setTimeout(() => {
            window.location.reload();
          }, 0);
               
    }

    register(user: any){
        let requestUrl = this.baseUrl + "/register";
        this.user=new User();
        this.user.email=user.email;
        return this.dataService.postJSON(requestUrl,user).pipe(
          map(response => response)
        );
    }

    forgotPassword(email: String){
        let requestUrl = this.baseUrl + "/password/forget";
        return this.dataService.postJSON(requestUrl, {
            email: email
        }).pipe(
          map(response => response)
        );
    }

    requestResetPassword(token:string){
        let requestUrl = this.baseUrl + "/password/change_password/" + token;
        return this.dataService.getJSON(requestUrl).pipe(
          map(response => response)
        );
    }

    resetPassword(password: String, token: String){
        let requestUrl = this.baseUrl + "/password/change_password";
        return this.dataService.postJSON(requestUrl, {
            token: token, pwd: password
        }).pipe(
          map(response => response)
        );
    }

    verifyEmail(token: string) {
        let requestUrl = this.baseUrl + "/verify/" + token;
        return this.dataService.getJSON(requestUrl).pipe(
          map(response => response)
        );
    }

    resendVerifyEmail(email: string) {
        let requestUrl = this.baseUrl + "/register/resend_confirm_email";
        return this.dataService.postJSON(requestUrl,{
            email:email
        }).pipe(
          map(response => response)
        );
    }
    
    changeProfile(profileName:any) {
        let requestUrl = this.baseUrl + "/api/change_profile";
        profileName.token=this.dataService.getToken();
        return this.dataService.postJSON(requestUrl,profileName).pipe(
          map(response => response)
        );
    }
    
    changePWD(profileName:any) {
        let requestUrl = this.baseUrl + "/api/change_password";
        profileName.token=this.dataService.getToken();
        return this.dataService.postJSON(requestUrl,profileName).pipe(
          map(response => response)
        );
    }
}
