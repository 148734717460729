import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DataService } from '../../../services/data.service';
declare var $ :any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RegisterComponent implements OnInit{
  model: any = {};
  MSGResponse: string;
  version = '';
  imageUrl: any;
  captchaResponse: string;

  constructor(private userService: UserService,private dataService:DataService, private router: Router, private loadingService: LoadingService ) {
    this.version=this.dataService.getVersion();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  onChangeFile($event): void {
    this.readFile($event.target);
  }

  readFile(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.model.passport_pic = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  onSubmit() {
    if(this.loadingService.isLoading)
      return
    this.userService.register(this.model)
      .subscribe(
        data1 => {
          this.handleResponse(JSON.stringify(data1));
        }
      );
  }

  handleResponse(data: string) {
    let obj = JSON.parse(data);
    // console.log(data);
    if (obj['status'] == 1) {
      this.router.navigateByUrl('/check-email');
    } else {
      this.MSGResponse = obj.message;
    }
  }

  handleSuccess(captchaResponse: string) {
    // console.log(`Resolved captcha with response ${captchaResponse}:`);
    this.captchaResponse = captchaResponse;
  }

}
