import { Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { UtilityService } from './services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoading = false;
  isOnline;

  constructor(private loadingService: LoadingService,private utilityService: UtilityService) {
    this.loadingService.loadingEventEmitter.subscribe(
      (isLoading) => {
        this.isLoading = isLoading;
      }
    );
    this.utilityService.getNetworkChangeObservable().subscribe(x => {
      this.isOnline = x;
    });
  }
}
