import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
})
export class TicketComponent implements OnInit {

    amount = 0;
    fullName:string; 
    isOrderActive=false;
    isProfileActive=false;
    isMyOrderActive=false;
    
    constructor(public userService: UserService,
                public dataService: DataService,
                private activatedRoute:ActivatedRoute) {

      // this.ticketService.getAccount().subscribe(data => this.handleAccountResponse(data),error =>  error);

        this.fullName = this.dataService.getFullName();
    }    

    ngOnInit() {
        this.activatedRoute.url.subscribe((data) => {
            let firstChild = this.activatedRoute.snapshot.firstChild
            if(firstChild){
                var path=firstChild.routeConfig.path
                this.isMyOrderActive=path.startsWith("order")||path.startsWith("receipt");
                this.isOrderActive=path.startsWith("step");
                this.isProfileActive=path.startsWith("profile");
            }
        });
    }
    
    handleAccountResponse(account) {
      var acc = account.accounts[0];

      this.amount = acc.amount;
    }

    logout(){
        this.userService.logout();
    }
}
