import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TicketType } from 'src/app/models/ticket-type';
import { TicketService } from 'src/app/services/ticket.service';
import { LoginPopupComponent } from 'src/app/components/users/login-popup/login-popup.component';
import { UserService } from 'src/app/services/user.service';
import { Ticket } from 'src/app/models/ticket';
import { MessageComponent } from 'src/app/components/message/message.component';
import { DataService } from '../../../../services/data.service';
import { UtilityService } from '../../../../services/utility.service';
declare var AbaPayway: any;

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.css']
})
export class StepThreeComponent implements OnInit {

  startDate: string;
  expiredDate: string;
  numberTicket: number;
  selectedTicketType: TicketType;
  isClickedSubmit = false;
  isAcceptTAC = false;
  tickets: Ticket[];
  paymentInfo: any;
  paymentKey: any;
  paymentUrl = "";
  orderId: string;

  constructor(private ticketService: TicketService,
    private userService: UserService,
    private dataService: DataService,
    private utilityService: UtilityService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.orderId = params["id"]);
    if (this.orderId && (!this.ticketService.orderId || this.orderId != this.ticketService.orderId)) {
      this.ticketService.getOrderHistoryDetail(this.orderId)
        .subscribe(data => {
          if (data.status == 1) {
            var o = data.order;
            if (o.status != 'unpaid') {
              return this.router.navigateByUrl('/order');
            }
            this.ticketService.selectedTicketType = o.ticket_type;
            this.ticketService.startDate = o.start_date.toString();
            this.ticketService.numberTicket = o.tickets.length;
            this.ticketService.orderId = this.orderId;
            this.ticketService.tickets = o.tickets;
            this.ticketService.canceledTickets = [];

            var start_date = new Date(o.start_date);
            var defaultDate = new Date();
            defaultDate.setUTCHours(0, 0, 0, 0)
            defaultDate.setDate(defaultDate.getDate() + 1)
            if (start_date < defaultDate) {
              this.router.navigateByUrl('/order/' + this.orderId + '/step/1');
            }

            if (!this.ticketService.tickets || this.ticketService.tickets.length < 1) {
              this.router.navigateByUrl('/order/' + this.orderId + '/step/2');
              return;
            }
            this.startDate = this.ticketService.startDate;
            this.expiredDate=this.ticketService.getExpiredDate();
            this.numberTicket = this.ticketService.numberTicket;
            this.selectedTicketType = this.ticketService.selectedTicketType;
            this.tickets = this.ticketService.tickets;
          }

        });
    } else if (!this.orderId && this.ticketService.orderId) {
      window.location.reload();
    } else {
      if (!this.ticketService.tickets || this.ticketService.tickets.length < 1) {
        this.router.navigateByUrl('/step/2');
        return;
      }
      this.startDate = this.ticketService.startDate;
      this.expiredDate=this.ticketService.getExpiredDate();
      this.numberTicket = this.ticketService.numberTicket;
      this.selectedTicketType = this.ticketService.selectedTicketType;
      this.tickets = this.ticketService.tickets;
    }
  }

  ngOnInit() {
    this.utilityService.load(()=>{},
      "https://payway-dev.ababank.com/checkout-popup.html?file=css",
      "https://payway-dev.ababank.com/checkout-popup.html?file=js"
    )
  }

  onClickSutbmit() {
    this.isClickedSubmit = true;
    if (this.isAcceptTAC) {
      if (this.userService.isLoggedIn()) {
        this.dataService.checkToken().subscribe(
          data => {
            if (data.status == 1) {
              this.makeOrder();
            } else {
              this.popupLogin(
                () => this.makeOrder()
              )
            }
          },
          error => {
            this.popupLogin(
              () => this.makeOrder()
            )
          }
        );
      } else {
        this.popupLogin(
          () => this.makeOrder()
        )
      }
    }
  }

  popupLogin(complete?: () => void) {
    const ref = this.dialog.open(LoginPopupComponent,
      {
        disableClose: false,
        role: 'dialog'
      });
    ref.afterClosed().subscribe(data => {
      if (data) {
        complete()
      }
    });
  }

  makeOrder() {
    if (this.orderId) {
      this.ticketService.postUpdateOrder()
        .subscribe(
          data => this.handle_success_order(data),
          error => {

          }
        );
    } else {
      this.ticketService.postOrder()
        .subscribe(
          data => this.handle_success_order(data),
          error => {

          }
        );
    }
  }

  handle_success_order(data) {
    if (data['status'] == 1) {
      this.paymentUrl = data.payment.aba_merchant_request;
      this.paymentInfo = data.payment;
      this.paymentKey = Object.keys(data.payment);
      if (this.paymentKey.indexOf("aba_merchant_request") >= 0) {
        this.paymentKey.splice(this.paymentKey.indexOf("aba_merchant_request"), 1)
      }

      //setTimeout to make callback funtion wait untile pay input tag finish render
      setTimeout(() => {
        AbaPayway.checkout();
      }, 0);

      this.ticketService.isFinished = true;
      //this.router.navigateByUrl('/finish');
    }
  }

  onCancel(route: string) {
    let dialogRef = this.dialog.open(MessageComponent, {
      data: {
        message: "MSG.CONFIRM_CANCEL",
        isConfirm: true,
      },
      disableClose: true, role: 'dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.href = route
      }
    });
  }
}
