import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ticket } from 'src/app/models/ticket';
import { UtilityService } from 'src/app/services/utility.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-my-order-detail',
  templateUrl: './my-order-detail.component.html',
  styleUrls: ['./my-order-detail.component.css']
})
export class MyOrderDetailComponent implements OnInit {

  id: string;
  order: any;
  // allTickets = [];
  summary = [];
  total = 0;
  orderDate:Date;

  constructor(private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService, 
    private ticketService: TicketService) {
    this.activatedRoute.params.subscribe(params => this.id = params["id"]);

    this.ticketService.getOrderHistoryDetail(this.id)
      .subscribe(data => this.responseDetail(data));
  }

  ngOnInit() {

  }

  responseDetail(response) {
    console.log(response)
    if (response['status'] == 1) {
      this.order = response['order'];
      this.orderDate=new Date(this.order.order_date)
    }
  }

  formatNumber(str: string) {
    return this.utilityService.formatNumber(str);
  }

}
