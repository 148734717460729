import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-prompt-check-email',
  templateUrl: './prompt-check-email.component.html',
  styleUrls: ['./prompt-check-email.component.css']
})
export class PromptCheckEmailComponent implements OnInit {
  responseMSG:string;
  isSuccess=false;
  isLoading=false;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {  
    if(!this.userService.user||!this.userService.user.email){
      this.router.navigateByUrl('/login')
    }
  }

  resendEmail() {
    this.responseMSG=null;
    this.isLoading=true;
    this.userService.resendVerifyEmail(this.userService.user.email)
    .subscribe(data =>  {
      this.isLoading=false;
      if(data.status == 1) {
        this.isSuccess=true;
        this.responseMSG='MSG.SENT_CONFIRM_EMAIL';
      }else{
        this.isSuccess=false;
        this.responseMSG=data.message;
      }
    });
  }

}
