import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { TicketService } from 'src/app/services/ticket.service';
import { OrderHistory } from 'src/app/models/order-history';
import { MessageComponent } from 'src/app/components/message/message.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent implements OnInit {

  rows = [];
  selected = [];
  count: number = 0;
  offset: number = 0;
  limit: number = 10;

  totalPrice = 0;

  p = 1;

  orders: OrderHistory[];

  constructor(
    public dataService: DataService, 
    private ticketService: TicketService, 
    private router: Router,
    private dialog: MatDialog) {
      this.getHistory();
  }

  ngOnInit() {
    
  }

  // get data from server
  getHistory() {
    this.ticketService.getOrderHistory(this.p)
      .subscribe(res => this.handleResponse(res));
  }

  handleResponse(response) {
    if (response.status == 1) {
      this.orders = response.orders;
      this.orders.forEach(function(order){
        order.order_date=new Date(order.order_date)
      })
    } 
  }

  nextPage() {
    if (this.orders.length == 10) {
      this.p++;
      this.getHistory();
    }
  }

  prevPage() {
    if (this.p > 1) {
      this.p--;
      this.getHistory();
    }
  }

  onEditDetail(o:OrderHistory) {
    this.ticketService.selectedTicketType=o.ticket_type;
    this.ticketService.startDate=o.start_date.toString();
    this.ticketService.numberTicket=o.number_tickets;
    this.ticketService.getOrderHistoryDetail(o._id)
      .subscribe(data => {
        if(data.status==1)
        this.ticketService.tickets=data.order.tickets;        
        this.router.navigateByUrl("step/3");
      });
    
  }

  onDelete(o:OrderHistory) {    
    let dialogRef = this.dialog.open(MessageComponent, {
      data:{
        message:"MSG.CONFIRM_DELETE_ORDER",
        isConfirm:true,
      },
      disableClose: false, role: 'dialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.ticketService.postCancelOrder(o._id)
        .subscribe(data => {
          if(data.status==1)
            this.getHistory();
        });
      }
    });
  }
}