import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  constructor(private ticketService: TicketService, private router: Router) {
    if(!this.ticketService.isFinished){
      this.router.navigateByUrl('/');
    }
   }

  ngOnInit() {
  }

}
