import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material';
import { MessageComponent } from 'src/app/components/message/message.component';


@Injectable({
    providedIn: 'root'
  })
export class DataService {
    //use for binding purpose
    fullName:string;
    baseUrl: string;
    id: string;


    constructor(private http: Http, private router: Router,private dialog: MatDialog,private loadingService:LoadingService) {
        this.baseUrl = environment.API_URL;        
    }

    saveToken(token) {
        //this.data.token = token;
        localStorage.setItem("token", token);
    }

    setName(firstName: string,lastName: string) {
        localStorage.setItem('first_name', firstName);
        localStorage.setItem('last_name', lastName);
        this.fullName=localStorage.getItem('first_name')+" "+localStorage.getItem("last_name")
    }

    getToken(): string {
        //return this.data.token;
        return localStorage.getItem("token");
    }

    getVersion() {
        return '1.0.0';
    }

    getFullName() {
        if(localStorage.getItem('first_name')&&localStorage.getItem("last_name")){
            this.fullName=localStorage.getItem('first_name')+" "+localStorage.getItem("last_name")
        }else{
            this.fullName="";
        }
        
        return this.fullName;
    }

    getFirstName() {
        return localStorage.getItem('first_name');
    }

    getLastName() {
        return localStorage.getItem("last_name");
    }
    
    /**
     * @param {string} returnUrl Url that return after logout.
     * @param {string} returnLoginUrl Url that return after login success.
     * */
    logout(returnUrl="login",returnLoginUrl?){
        localStorage.removeItem('token');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        this.router.navigate([returnUrl], { queryParams: { returnUrl:  returnLoginUrl }});
    }

    postJSON(url: string, data: any,isAlertOnError:boolean=false) {
        this.loadingService.setLoading(true);
        let headers = new Headers({
            'Content-Type': 'application/json'
        });

        return this.http.post(url, data, { headers }).pipe(
            map(response => {
                this.loadingService.setLoading(false);
                let res = response.json();
                if (res.status == 401) {
                    this.logout("login",this.router.url);
                }else if(res.status!=1&&isAlertOnError){
                    this.dialog.open(MessageComponent, {
                        data:{
                          message:res.message,
                          isConfirm:false,
                        },
                        disableClose: false, role: 'dialog'
                      });
                }
                return res;
            }));
    }


    post(url: string, data: any) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.loadingService.setLoading(true);
        return this.http.post(url, data, { headers: headers }).pipe(
            map(response => {
                this.loadingService.setLoading(false);
                let res = response.json();
                if (res.status == 401) {
                    this.logout("login",this.router.url);
                }
                return res;
            }));
    }

    getJSON(url: string,isAlertOnError:boolean=false) {
        this.loadingService.setLoading(true);
        return this.http.get(url).pipe(
            map(response => {
                this.loadingService.setLoading(false);
                let res = response.json();
                if (res.status == 401) {
                    this.logout("login",this.router.url);
                }
                else if(res.status!=1&&isAlertOnError){
                    this.dialog.open(MessageComponent, {
                        data:{
                          message:res.message,
                          isConfirm:false,
                        },
                        disableClose: false, role: 'dialog'
                      });
                }
                return res;
            })
        );
    }
    
    checkToken() {
        this.loadingService.setLoading(true);
        return this.http.get(
            this.baseUrl+"/api/check_token",
            {
                params:   {token:localStorage.getItem("token")}
            }
        ).pipe(
            map(response => {
                this.loadingService.setLoading(false);
                let res = response.json();
                return res;
            })
        );
    }
}
