import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ChatService} from '../services/chat.service';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs/internal/observable/empty';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class ChatAuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.API_CHAT_URL)) {
      const token = localStorage.getItem(ChatService.TokenKey);
      if (token) {
        const request = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
        return next.handle(request).pipe(catchError(err => {
          if (err instanceof HttpErrorResponse) {
            return this.onError(err);
          }
        }));
      }
    }
    return next.handle(req);
  }

  private resetToken() {
    localStorage.removeItem(ChatService.TokenKey);
  }

  private onError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.resetToken();
      return EMPTY;
    }
    return throwError(error);
  }

}
