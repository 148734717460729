import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import Cropper from 'cropperjs';


@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {

  @ViewChild('imageCropper') imageCropper: ElementRef;
  cropper: Cropper;
  hideImageCropper = true;


  constructor(private dialogRef: MatDialogRef <ImageCropperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {

  }

  resetCrop(): void {
    this.imageCropper.nativeElement.src = this.data;
    this.hideImageCropper = true;
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper.clear();
      this.cropper = null;
    }

  }

  onSaveClick(): void {
    this.dialogRef.close(this.cropper.getCroppedCanvas());
  }

  ngOnInit() {
    this.resetCrop();
    this.cropper = new Cropper(this.imageCropper.nativeElement, {
      responsive: true,
      aspectRatio: 1,
      viewMode: 2,
      rotatable: true
    });

  }

}
