import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Account, ChatService, DataMessage, Room} from '../../services/chat.service';
import {MatDialog} from '@angular/material';
import {debounceTime, filter, flatMap, mapTo, tap} from 'rxjs/operators';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {merge} from 'rxjs';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.css'],
  providers: [ChatService],
  animations: [trigger('cardAnimation', [
    state('void', style({transform: 'translateY(20px)', opacity: 0})),
    transition('void <=> *', animate('.20s ease-out'))
  ]),
    trigger('iconAnimation', [
      state('void', style({opacity: 0, transform: 'scale(0) rotate(45deg)'})),
      transition('void <=> *', animate('.25s ease-out'))
    ]),
    trigger('iconAnimationTwo', [
      state('void', style({opacity: 0, transform: 'scale(0) rotate(-45deg)'})),
      transition('void <=> *', animate('.25s ease-out'))
    ]),
    trigger('overlapCardAnimation', [
      state('void', style({opacity: 0})),
      transition('void <=> *', animate('.25s ease-out'))
    ]),
    trigger('zoomIn', [
      state('void', style({transform: 'scale(0) translateY(-200px)', opacity: 0})),
      transition('void => *', animate('.35s ease-in-out'))
    ]),
    trigger('slideDown', [
      state('void', style({transform: 'translateY(100px)', opacity: 0})),
      transition('void => *', animate('.3s ease-out'))
    ]),
    trigger('sendAnimation', [
      state('hide', style({
        opacity: 0,
        transform: 'scale(0) rotate(-25deg)'
      })),
      state('show', style({
        opacity: 1,
        transform: 'scale(1) rotate(0)'
      })),
      transition('hide <=> show', animate('.15s ease-out'))
    ]),
    trigger('chatContent', [
      state('void', style({opacity: 0})),
      transition('void => *', animate('.2s ease-out'))
    ]),

    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({opacity: 0}), {optional: true}),
        query(':enter', stagger('-100ms', [
          animate('.2s ease-out', keyframes([
            style({opacity: 0, transform: 'translateY(25%)', offset: 0}),
            style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class ChatboxComponent implements OnInit {


  public showContent = false;
  public showChat = false;
  public showMessages = false;
  private _isLoading = false;

  public isGlobalOnline = false;
  public messageControl = new FormControl('', [Validators.required]);
  public messageListScrollHeight = 0;
  public messageListScrollTop = 0;
  public showTypingIndicator = false;

  public messages: DataMessage[] = [];
  public currentAccount: Account;
  public currentRooms: Room[];
  public chatRoom: Room;

  set isLoading(value: boolean) {

    if (value) {
      this.infoFormGroup.disable();
      this.messageControl.disable();
    } else {
      this.infoFormGroup.enable();
      this.messageControl.enable();
    }

    this._isLoading = value;
  }

  get isLoading() {
    return this._isLoading;
  }

  public infoFormGroup: FormGroup;
  private messageList: ElementRef<HTMLDivElement>;
  private inputElement: ElementRef<HTMLInputElement>;


  @ViewChild('inputMessage') set inputMessage(input: ElementRef<HTMLInputElement>) {
    if (input) {
      input.nativeElement.focus();
      this.inputElement = input;
    }
  }


  @ViewChild('messageList') set content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.messageList = content;
      content.nativeElement.scrollTop = content.nativeElement.scrollHeight;
    }
  }

  constructor(private chat: ChatService,
              private dialog: MatDialog,
              private formBuilder: FormBuilder) {

    this.infoFormGroup = formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      message: ['', Validators.required]
    });

  }

  ngOnInit(): void {

    this.chat.fromEvent('global-presence').subscribe(pre => {
      if (pre) {
        this.isGlobalOnline = pre.isOnline;
      }

    });

    merge(this.chat.fromEvent('connect').pipe(mapTo(true)),
      this.chat.fromEvent('disconnect').pipe(mapTo(false)))
      .subscribe(isConnected => {
        console.log('Socket.io is ' + (isConnected ? 'connected' : 'disconnected'));
        if (isConnected) {
          if (this.chat.hasStoredToken()) {
            this.chat.authenticateSocket(this.chat.getStoredToken());
          }
        }

      });

    this.chat.fromEvent('authenticated').subscribe(() => {
      this.checkStoredToken();
    });


    this.chat.fromEvent('room-close').subscribe(room => {
      console.log(room);
      // this.clearCredentials();
      this.setCurrentRooms([room]);
    });


    this.chat.fromEvent<DataMessage>('message').subscribe(message => {
      this.messages.push(message);
      this.showTypingIndicator = false;
    });

    this.messageControl
      .valueChanges
      .pipe(filter(a => !!a), debounceTime(100))
      .subscribe(() => this.chat.emitTypingToRoom(this.chatRoom._id));

    merge(this.chat.fromEvent('start-typing').pipe(mapTo(true)),
      this.chat.fromEvent('stop-typing').pipe(mapTo(false)))
      .subscribe(typing => this.showTypingIndicator = typing);

    this.chat.fromEvent('presence').subscribe(data => console.log(data));
    this.checkExpired();
  }

  private checkExpired() {
    const date = localStorage.getItem('exp');
    if (!date) {
      this.clearCredentials();
    } else {
      const timestamp = +date;
      if (timestamp < Date.now()) {
        this.clearCredentials();
        localStorage.removeItem('exp');
      }
    }
  }

  checkStoredToken() {
    if (!this.chat.hasStoredToken()) {
      return;
    }
    this.isLoading = true;
    this.chat.authByToken(this.chat.getStoredToken())
      .subscribe(value => {
        console.log(value);
        this.setCurrentAccount(value.account);
        this.initialChatApp();
      }, () => {
        this.clearCredentials();
      });
  }

  initialChatApp() {
    this.chat.getReadyRooms()
      .pipe(tap(() => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      }))
      .subscribe(rooms => {
        console.log(rooms);
        if (rooms.length > 0) {
          this.setCurrentRooms(rooms);
          this.fetchMessages();
        } else {
          this.clearCredentials();
        }
      });

    this.chat.getGlobalPresence().subscribe(pre => {
      if (pre) {
        this.isGlobalOnline = pre.isOnline;
      }
    });
  }

  onSendMessage() {
    if (this.messageControl.invalid) {
      return;
    }

    const message = this.messageControl.value;
    this.chat.createTextMessage({body: message, roomId: this.chatRoom._id})
      .subscribe(value => {
        console.log(value);
        this.messageControl.reset();
      });
  }

  toggleContent() {
    this.showContent = !this.showContent;
  }

  doneAnimation() {
    this.showMessages = true;
  }

  backFromChat() {
    this.showConfirmDialog();
  }

  public showConfirmDialog() {
    this.dialog.open(ConfirmationDialogComponent).afterClosed()
      .subscribe(result => {
        if (result) {
          this.clearCredentials();
        }
      });
  }


  clearCredentials() {
    if (this.chatRoom) {
      this.chat.closeRoom(this.chatRoom._id).subscribe(room => {

      });
    }
    this.messages = [];
    // Unsubscribe to the room
    this.currentRooms = null;
    this.currentAccount = null;
    this.chatRoom = null;
    this.chat.socket.close();
    this.chat.clearToken();
    this.showMessages = false;
    this.showChat = false;
  }

  setCurrentAccount(account: Account) {
    this.currentAccount = account;
    if (!localStorage.getItem('exp')) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      localStorage.setItem('exp', date.getTime().toString());
    }
  }

  setCurrentRooms(rooms: Room[]) {
    this.currentRooms = rooms;
    this.chatRoom = rooms[0];
    if (this.chatRoom) {
      this.showChat = true;
      this.chat.joinRoom(this.chatRoom._id);
      if (this.chatRoom.status !== 1) {
        this.messageControl.disable();
      } else {
        this.messageControl.enable();
      }
    }
  }

  fetchMessages(roomId: string = this.chatRoom._id) {
    this.chat.getMessages(roomId)
      .subscribe(messages => {
        console.log(messages);
        this.messages = messages;
      });
  }

  submitInfo() {

    if (this.infoFormGroup.invalid) {
      return;
    }

    this.chat.postCreateGuest(this.infoFormGroup.value)
      .pipe(tap((response) => {
          this.setCurrentAccount(response.account);
          this.chat.saveToken(response.token);
          this.authSocket(response.token);
          this.chat.socket.connect();
        }),
        flatMap(res => this.chat.createRoom({name: res.account.name, maxLength: 2})),
        tap(room => this.setCurrentRooms([room])),
        flatMap(room => this.chat.createTextMessage({body: this.infoFormGroup.value.message, roomId: room._id})))
      .subscribe(message => {
        this.fetchMessages(this.chatRoom._id);
        this.infoFormGroup.reset();
      });

  }

  private authSocket(token: string) {
    this.chat.authenticateSocket(token);
  }

  checkIfMyMessage(message: DataMessage) {
    return message.sender === this.currentAccount._id;
  }
}
